@import '../colors';
@import '../fonts';

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 30px $white-five inset !important;
}

input, textarea {
  outline: none;
}

textarea:hover,
input:not[type="checkbox"]:hover,
textarea:active,
input:not[type="checkbox"]:active,
textarea:focus,
input:not[type="checkbox"]:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline: 0 !important;
  -webkit-appearance: none !important;
  box-shadow: none !important;
}

.standard-form {
  width: 400px;

  &.wide {
    width: 446px;
  }
  &.small-form{
      width: 320px;
  }
  .form-field {
    position: relative;
  }

  .form-title {
    color: $black;
    @include secondary-font-bold;
    font-size: 44px;
    text-align: left;
    line-height: 48px;

    strong {
      color: $orange;
    }
  }

  .section-title {
    color: $black;
    @include secondary-font-bold;
    font-size: 20px;
    text-align: left;
    line-height: 48px;
  }

  .form-subtitle {
    font-size: 14px;
    color: $light-grey;
    text-align: left;
    line-height: 24px;
  }
  .link-button-field + .link-button-field {
    margin-top: 22px;
  }

  .form-title + .form-subtitle {
    margin-top: 32.14px;
  }

  .form-subtitle + .form-subtitle {
    margin-top: 30.14px;
  }



  .form-button button + button {
    margin-left: 12px;
  }

  .professions-list{
      margin-top: 22px;
  }

  .error-wrapper {
    position: absolute;
    top: -52px;
    right: -200px;
    background: #F0EFEB;
    width: 188px;
    padding: 20px 24px 27px 28px;

    p {
      margin-bottom: 0px;
    }

    .cursor {
      position: absolute;
      top: 68px;
      left: -4px;
      width: 0px;
      height: 0px;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      border-right: 4px solid #F0EFEB;
    }

    .error-title {
      color: $black;
      font-size: 14px;
      line-height: 20px;
      @include secondary-font-regular;
    }

    .error-subtitle {
      color: $light-grey;
      font-size: 13px;
      margin-top: 12px;
      line-height: 20px;
    }
  }

  .w-100 {
    width: 100%;
  }

  .email-sent-wrapper {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 400px;
  }

  img {
    &.stamp {
      width: 143px;
      margin-top: -12px;
      margin-left: -15px;
    }
  }
}
