

@mixin secondary-font-light {
  font-family: 'sofia_pro_light', sans-serif;
}

@mixin secondary-font-regular {
  font-family: 'sofia_pro_regular', sans-serif;
}

@mixin secondary-font-medium {
  font-family: 'sofia_pro_medium', sans-serif;
}

@mixin secondary-font-semi-bold {
  font-family: 'sofia_pro_semi_bold', sans-serif;
}

@mixin secondary-font-bold {
  font-family: 'sofia_pro_bold', sans-serif;
}

@mixin sofia-pro-black {
  font-family: "Sofia Pro Black", sans-serif;
}
