@import 'src/style/colors';
@import 'src/style/fonts';

body button {
  @include secondary-font-regular;
  min-width: 96px;
  height: 40px;
  border-radius: 0;
  font-size: 14px;
  text-align: left;
  outline: none !important;
  cursor: pointer;
  border: none;
  width: auto;
  line-height: 26px;
  padding: 0px 24px;
  white-space: nowrap;

  &.ngx-material-timepicker-toggle {
    min-width: unset;
    height: unset;
  }

  &.primary {
    background: #1B1C1E;
    color: $white-third;

    &:hover {
      background: #323232;
    }

    &:active {
      background: #1B1C1E;
    }

    &:disabled {
      background: #bcbcbc;
      color: $white-third;
      opacity: 100%;
      cursor: not-allowed;
    }
  }

  &.secondary {
    box-sizing: border-box;
    height: 40px;
    min-width: 96px;
    border: 1px solid #E3E2E2;
    background-color: #FFFFFF;
    text-align: center;

    &:hover {
      border: 1px solid #E3E2E2;
      background-color: #FFFFFF;
    }

    &:active {
      background-color: $white-second;
    }

    &:disabled {
      background: #FEFEFE;
      opacity: 100%;
      cursor: not-allowed;
    }
  }

  &.highlighted {
    background: $orange;
    color: $white-third;

    &:hover {
      background: $orange;
    }

    &:active {
      background: $orange;
    }

    &:disabled {
      background: #bcbcbc;
      color: $white-third;
      opacity: 100%;
      cursor: not-allowed;
    }
  }

  &.icon-button {
    padding-right: 70px;
    position: relative;
    min-width: 112px;

    &:after {
      content: "";
      height: 16px;
      width: 16px;
      position: absolute;
      top: 12px;
      bottom: 12px;
      right: 24px;
    }

    &.arrow-right:after {
      background-size: contain;
      background-image: url('/assets/img/auth/arrow-right.png');
    }

    &.check-mark:after {
      background-size: contain;
      background-image: url('/assets/img/management/checkmark_icon.svg');
    }

    &.password-icon:after {
      background-size: contain;
      background-image: url('/assets/img/management/password_icon.svg');
    }

    &.delete-icon:after {
      background-size: contain;
      background-image: url('/assets/img/management/delete_icon.svg');
    }
  }

  &.icon-only {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 0;
    outline: none;
    background: #FEFEFE;
    border: 1px solid #dddcdb;
    position: relative;
    min-width: 40px;
    min-height: 40px;
    padding: 0px;

    &.form-top {
      position: absolute;
      top: 104px;
    }

    &:hover {
      background: #FEFEFE;
    }

    &:active {
      background-color: $white-second;
    }

    &:disabled {
      background: #FEFEFE;
      opacity: 100%;
      cursor: not-allowed;
    }

    &:after {
      content: "";
      height: 16px;
      width: 16px;
    }

    &.back-icon:after {
      background-size: contain;
      background-image: url('/assets/img/auth/arrow-left.png');
    }

    &.close-icon:after {
      background-size: contain;
      background-image: url('/assets/img/project/close_icon.png');
    }
  }

  &.emoji-button {
    min-width: 34px;
  }
}

.link-button {
  @include secondary-font-regular;
  color: $orange;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
  line-height: 26px;
  padding: 0;
  &:hover {
    text-decoration: none;
  }
  &:active{
      text-decoration: none;
  }

  &.small {
    font-size: 13px;
  }
}
