@import "assets/fonts/fonts.css";
@import "style/colors";
@import "style/main";
@import "style/libs";

* {
    scroll-behavior: smooth;
}

button{
    -webkit-appearance: none
}

.font-bold{
    font-weight: bold;
}