@font-face {
  font-family: 'barlow_light';
  src: url('src/assets/fonts/barlow-v1-latin-300.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'barlow_regular';
  src: url('src/assets/fonts/barlow-v1-latin-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'barlow_medium';
  src: url('src/assets/fonts/barlow-v1-latin-500.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'barlow_semi_bold';
  src: url('src/assets/fonts/barlow-v1-latin-700.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'barlow_bold';
  src: url('src/assets/fonts/barlow-v1-latin-900.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'barlow_extra_bold';
  src: url('src/assets/fonts/barlow-v1-extra-bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'sofia_pro_light';
  src: url('src/assets/fonts/SofiaProLight.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'sofia_pro_regular';
  src: url('src/assets/fonts/sofia_pro_regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'sofia_pro_medium';
  src: url('src/assets/fonts/SofiaPro-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;

}

@font-face {
  font-family: 'sofia_pro_semi_bold';
  src: url('src/assets/fonts/SofiaProSemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;

}

@font-face {
  font-family: 'sofia_pro_bold';
  src: url('src/assets/fonts/SofiaPro-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;

}

@font-face {
  font-family: 'Sofia Pro Black';
  src: url('src/assets/fonts/SofiaPro-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;

}
