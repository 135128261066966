@import 'src/style/fonts';
@import 'src/style/colors';

.profession {
  @include secondary-font-regular;
  padding: 5px 12px 7px 12px;
  background: $white-six;
  color: $black;
  font-size: 13px;
  line-height: 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  white-space: nowrap;
  display:flex;
  align-items:center;
  img {
      margin-left: 8px;
      cursor: pointer;
  }

  &.task {
    background: #e5e5e5;
    color: $light-grey;
    font-size:11px;
    margin-bottom: 0px;
    padding: 2px 12px;
  }
}
