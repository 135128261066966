@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '../../../node_modules/@angular/material/theming';
@import '../colors';
@import '../fonts';

$custom-typography: mat-typography-config(
  $font-family: 'sofia_pro_regular',
  $headline: mat-typography-level(32px, 48px, 700),
  $body-1: mat-typography-level(16px, 24px, 500)
);
@include angular-material-typography($custom-typography);

.mat-form-field {
  padding: 4px;
  width: 80%;

  @media (max-width: 835px) {
    width: 100%;
  }

  button {
    border: none !important;
    outline: none !important;
  }

  .input-search {
    padding-left: 23px !important;
  }

  .event-input {
    font-size: 14px !important;
    font-weight: bold !important;
    color: $black !important;
  }

  .assignee-text {
    margin-bottom: 0 !important;
    font-size: 14px !important;
    font-weight: bold !important;
    color: $black !important;
  }

  .stage-title {
    margin-bottom: 0 !important;
    font-size: 14px !important;
    font-weight: bold !important;
    color: $black !important;
  }

  .visibility {
    cursor: pointer !important;
    font-size: 13px !important;
  }

  .cursor-icon {
    cursor: pointer !important;
  }

  .stage-value {
    width: 10px !important;
    height: 10px !important;
    border-radius: 50% !important;
    margin-left: 1rem !important;
  }

  .remove-assignee {
    margin-left: 6px !important;
    position: absolute !important;
    margin-top: 6px !important;
    cursor: pointer !important;
  }

  .white-icon {
    cursor: pointer !important;
  }

  .white-select {
    color: $white-default !important;
  }

  .location-icon {
    margin-left: -16px !important;
    position: absolute !important;
    margin-top: 0 !important;
    cursor: pointer !important;
  }

  .mat-form-field-infix {
    padding: 3px 0px 12px 0px;
    border-top-width: 14px;
  }

  .mat-form-field-prefix, .mat-form-field-suffix {
    white-space: nowrap;
    flex: none;
    position: absolute;
    right: 0px;
    top: 14px;
  }

  .mat-select-value {
    line-height: 26px;
    font-size: 14px;
    line-height: 26px;
  }

  .mat-select-value-text {
    @include secondary-font-regular;
    color: $black;

    .mat-icon {
      position: absolute;
      right: 0px;
    }

    span {
      @include secondary-font-regular;
      color: $black;
    }
  }
  //Input
  .mat-form-field-underline, .mat-form-field-ripple {
    background-color: white !important;
  }

  .mat-form-field-ripple {
    display: none !important;
  }
  //mat-input focused color
  &.mat-focused .mat-form-field-underline {
    background-color: white !important;
  }
  // Input element - Font, caret, color
  .mat-input-element {
    caret-color: #118C93;
    line-height: 26px;
    font-size: 16px;
    @include secondary-font-regular;
    color: #118C93 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
  }

  // Form label - Empty
  .mat-form-field-label {
    margin-top: -10px;
    font-size: 16px;
    line-height: 1.4;
    @include secondary-font-regular;
    color: #118C93 !important;
  }
  // Form label - Focused

  &.mat-focused .mat-form-field-label {
    color: #118C93 !important;
  }
  //Form label - Floating
  &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.28125em) scale(1) perspective(100px) translateZ(0.001px) !important;
    font-size: 13px;
    -webkit-transform: translateY(-1.28125em) scale(1) perspective(100px) translateZ(0.001px) !important;
    -ms-transform: translateY(-1.28125em) scale(1) !important;
  }
  // Form label - Error

  &.mat-form-field-invalid .mat-form-field-label {
    color: #ff0033 !important;
  }
  //Icon
  .mat-icon {
    color: $black !important;
  }

  &.password-login {

    .mat-form-field-suffix {
      .mat-icon {
        position: absolute;
        top: 10px;
        right: 0;
      }
    }
  }
}

.mat-form-field-outline {
  height: 60px !important;
}

.mat-form-field-wrapper {
  padding-bottom: 0 !important;
  height: 60px !important;
}

.mat-form-field-flex {
  height: 60px !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-end, .mat-form-field-appearance-outline .mat-form-field-outline-start {
  border-radius: 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #118C93 !important;
}

.mat-form-field.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  width: fit-content;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper{
    padding-bottom: 0 !important;
}
.mat-form-field-appearance-legacy .mat-form-field-underline{
    bottom: 0;
}

.form-field {
  .form-checkbox {
    margin-bottom: 0 !important;
    padding-right: 0 !important;

    .checkbox-label {
      @include secondary-font-regular;
      font-size: 16px;
      line-height: 24px;
      padding-left: 13px;
      margin-bottom: 0;
      width: 100%;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        text-decoration: none;
      }
    }
  }

  .checkmark-terms {
    top: 5px;
    margin-top: 0;
  }

  .form-checkbox .checkmark {
    border: 1px solid #07878F;
  }

  .form-checkbox .checkmark:after {
    height: 6px !important;
    width: 6px !important;
  }
}

.mat-calendar-body-selected {
  background-color: #00594f !important;
}

.stage-color-circle {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
  margin-left: 1rem !important;
}

.mat-option[aria-disabled=true] {
  cursor: not-allowed !important;
}

.mat-select-trigger {
  height: 36px !important;
  padding: 4px 0 !important;

  span {
    color: #118C93 !important;
  }
}

.mat-select-arrow {
  margin-top: 12px !important;
  color: #118C93 !important;
}

.mat-select-panel {
  margin-top: 10px !important;
}

.mat-pseudo-checkbox {
  width: 16px;
  height: 16px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  position: relative;
  background-color: white !important;
  border: 1px solid #eee !important;
  border-radius: 0 !important;
  flex-shrink: 0;
  transition: border-color 90ms cubic-bezier(0, 0, .2, .1), background-color 90ms cubic-bezier(0, 0, .2, .1);
}

.mat-pseudo-checkbox::after {
  position: absolute;
  opacity: 0;
  content: '';
  border-bottom: 2px solid currentColor;
  transition: opacity 90ms cubic-bezier(0, 0, .2, .1);
}

.mat-pseudo-checkbox-checked {
  background: white !important;
  border: 1px solid $black !important;
}

.mat-option {
  font-size: 14px !important;
  font-weight: bold !important;
  color: $warm-grey !important;
  line-height: 20px !important;
  height: 40px !important;
  padding: 0px 25px !important;

  .mat-option-pseudo-checkbox {
    margin-right: 14px !important;
  }

  &:active {
    background-color: white !important;
  }

  &.mat-active {
    background-color: white !important;
  }

  &:hover {
    background-color: #F3F2EE !important;
  }
}

.mat-pseudo-checkbox-checked:after {
  left: 2px !important;
  top: 4px !important;
  width: 6px !important;
  height: 6px !important;
  background: #00594f !important;
  transform: rotate(0) !important;
  border-left: 2px solid currentColor !important;
  opacity: 1;
  box-sizing: content-box;
}

.multiple-dropdown {
  margin-top: 44px;
  max-height: 498px;
  border-top: 1px solid #00594f;
  min-width: 100% !important;
  max-width: 100%;
  margin-left: 40px;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  border-radius: 0 !important;

  .mat-option.mat-selected:not(.mat-option-disabled) {
    font-size: 14px !important;
    font-weight: bold !important;
    color: $black !important;
  }

  &.mat-menu-above {
    border-top: none;
    border-bottom: 1px solid #00594f;
  }
}

.single-dropdown {
  margin-top: 44px;
  border-top: 1px solid #00594f;
  min-width: 100% !important;
  max-width: 100%;
  max-height: 498px;
  margin-left: 40px;
  border-radius: 0 !important;
  padding-bottom: 10px !important;
  padding-top: 10px !important;

  .mat-option {
    position: relative;
    p{
        margin-bottom: 0px;
    }
  }

  .mat-option.mat-selected:not(.mat-option-disabled) {
    color: #00594f !important;
  }

  &.mat-menu-above {
    border-top: none;
    border-bottom: 1px solid #00594f;
  }
}

.timepicker__header {
  background-color: #00594f !important;
}

.timepicker-dial__control {
  outline: none !important;
  border: none !important;
}

.clock-face__number > span.active {
  background-color: #00594f !important;
}

.clock-face__clock-hand {
  background-color: #00594f !important;

  &:after {
    background-color: #00594f !important;
  }
}

.clock-face__clock-hand_minute:before {
  border: 4px solid #00594f !important;
}

.timepicker-button {
  color: #00594f !important;
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff0033 !important;
}

.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
  display: block;
  font-size: inherit;
  width: 24px !important;
  height: 22px !important;
  position: absolute !important;
  right: 12px !important;
  top: 20px !important;
}
