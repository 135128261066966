@import 'src/style/colors';
@import 'src/style/fonts';

html, body {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  @include secondary-font-regular;

  p, i, a, h1, h2, h3, h4, h5, h6, span, input, textarea {
    @include secondary-font-regular;
  }
  //Dropdown
  .ui-dropdown {
    display: flex !important;
    justify-content: flex-end !important;
  }

  .ui-dropdown-panel {
    width: 176px !important;
    box-shadow: 0 8px 24px 0 rgba(220, 219, 212, 0.56) !important;
    border-radius: 0 !important;
    border: none !important;
    border-top: 1px solid $orange !important;
    background-color: $white-default !important;
    margin-top: 4px !important;
    padding: 16px 0 !important;
    margin-left: -30px !important;
  }

  .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight {
    background-color: $white-six !important;
  }

  .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item {
    color: $black !important;
    height: 40px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;

    &:hover {
      background-color: $white-six !important;
    }
  }

  .ui-inputtext {
    width: 100%;
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #212529 !important;
    outline: none !important;
    font-size: 13px !important;
    color: $black !important;
    background-color: transparent !important;
    @include secondary-font-regular;

    &:hover {
      border-bottom: 1px solid #212529 !important;
    }

    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .ui-float-label > label {
    @include secondary-font-regular;
  }

  .ui-dropdown {
    background-color: transparent !important;
    min-width: 50px !important;
  }

  .ui-dropdown .ui-dropdown-trigger {
    background-color: transparent !important;
  }

  .ui-dropdown .ui-dropdown-trigger .ui-dropdown-trigger-icon {
    color: $black !important;
    font-size: 16px !important;
  }

  ::-webkit-input-placeholder { /* Edge */
    @include secondary-font-regular;
    color: $light-grey;
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    @include secondary-font-regular;
    color: $light-grey;
  }

  ::placeholder {
    @include secondary-font-regular;
    color: $light-grey;
  }

  .opened {
    .ck.ck-sticky-panel {
      left: 336px;
    }
  }

  .opacity {
    .ck.ck-sticky-panel {
      opacity: 0.3;
    }
  }

  .ck.ck-icon :not([fill]) {
    fill: none;
  }

  .ck.ck-icon {
    width: 16px;
    height: 16px;
    font-size: unset;
    will-change: transform;
  }

  .ck.ck-button:not(.ck-disabled):hover, a.ck.ck-button:not(.ck-disabled):hover {
    background: #F3F2EE;
  }

  .ck.ck-button:not(.ck-disabled):active, a.ck.ck-button:not(.ck-disabled):active {
    background: #F3F2EE;
  }

  .ck.ck-button.ck-on, a.ck.ck-button.ck-on {
    background: #F3F2EE;

    &:hover, &:active {
      background: #efefee;
    }
  }

  .ck.ck-button.ck-on.ck-dropdown__button {
    background: transparent;
  }

  .ck.ck-dropdown .ck-dropdown__arrow {
    width: 10px;
    fill: #71706F;
  }

  .ck.ck-sticky-panel {
    position: fixed;
    top: 52px;
    left: 136px;
    z-index: 99999;
  }

  .ck.ck-toolbar {
    background: #FFFFFF;
    border: none;
  }

  .ck .ck-editor__main {
    width: 536px;
    @include secondary-font-regular;
    padding-right: 0px;
  }

  .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
    border: none;
    padding-left: 0;
    @include secondary-font-regular;
  }

  .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
    border: none;
    outline: none;
    box-shadow: none;
    padding-left: 0;
    @include secondary-font-regular;
  }

  .ck-placeholder {
    height: 20px;
    width: 536px;
    opacity: 0.3;
    color: #212121;
    @include secondary-font-regular;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }

  .ck.ck-toolbar .ck.ck-toolbar__separator {
    height: 16px;
    opacity: 0.2;
    background-color: #71706F;
    margin: 12px 0px 0 0px;
  }

  .ck.ck-reset .ck-dropdown__panel {
    min-width: 112px;
    border: none;
    border-top: 1px solid $orange;
  }

  .ck.ck-dropdown.ck-heading-dropdown .ck-dropdown__panel .ck-list__item {
    min-width: 90px;
  }

  .ck.ck-editor__editable_inline > :first-child {
    margin-top: 0px;
  }

  .ck.ck-editor__editable_inline {
    p {
      font-size: 14px;
      line-height: 28px;
      margin-bottom: 12px;
    }
  }

  .ck .ck-toolbar__items {
    & > .ck + .ck {
      margin-left: 16px;
    }

    & > .ck + .ck-file-dialog-button {
      margin-left: 16px;
    }

    & > .ck-file-dialog-button + .ck {
      margin-left: 16px;
    }
  }

  .ck.ck-dropdown.ck-heading-dropdown {
    width: 112px;
    padding: 0;


    .ck.ck-list__item .ck-button.ck-on, .ck.ck-list__item .ck-button.ck-on:hover:not(.ck-disabled) {
      color: #FFFFFF !important;
      background-color: $orange;
      width: 100%;
    }

    button {
      padding: 0px 10px;
      color: #71706F;
      width: 112px;


      span:nth-child(2) {
        height: 26px;
        width: 72px;
        @include secondary-font-regular;
        font-size: 13px;
        font-weight: bold;
        line-height: 26px;
        letter-spacing: 0;
      }

      svg {
        min-width: 8px;
      }
    }
  }

  ul.todo-list {
    padding-left: 25px;
  }

  .ck-content .todo-list li{
      font-size: 14px;
  }

  .ck-content .todo-list .todo-list__label > input {
    left: -25px;
    margin-right: -15px;
    border: 1px solid #eee;
    width: 16px;
    height: 16px;
    margin-top: -2px;
  }

  .ck-content .todo-list .todo-list__label > input[checked] {
    border: 1px solid $black;
  }

  .ck-content .todo-list .todo-list__label > input:before {
    border-radius: 0px;
    border: none;
  }

  .ck-content .todo-list .todo-list__label > input[checked]:before {
    background: white;
  }

  .ck-editor__editable .todo-list .todo-list__label > input:hover:before {
    box-shadow: none;
  }

  .ck-content .todo-list .todo-list__label > input[checked]:after {
    left: 4px;
    top: 4px;
    width: 6px;
    height: 6px;
    background: $orange;
    transform: none;
    border: none;
  }
}

.basic-view-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &.file-over {
    opacity: 10%;
  }

  input {
    width: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .inner-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .default-image-wrapper {
      width: 320px;
      height: 320px;

      img {
        width: 100%;
        height: auto;
      }
    }

    .default-form-wrapper {
      height: 100%;
      margin-left: 104px;
    }
  }

  .default-image-wrapper {
  }
}

.new-item-wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;

  &.new-project-screen {
    z-index: 9999;
  }

  .item-pagination {
    display: flex;
    align-items: center;

    p {
      font-size: 13px;
      margin-left: 24px;
      @include secondary-font-regular;
      color: $black;
      margin-bottom: 0;

      strong {
        @include secondary-font-bold;
      }
    }
  }

  .item-background {
    position: absolute;
    width: 248px;
    background-color: $warm-white;
    right: 0px;
    height: 100vH;
    top: 0px;
  }

  .item-wrapper {
    height: 100vh;
    padding-top: 174px;
    width: 464px;
    margin: 0 auto;
    position: relative;

    .item-logo {
      position: absolute;
      top: 164px;
      left: -264px;

      img {
        width: 160px;
        height: 160px;
      }

      .image-circle {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 128px;
        height: 128px;
        border-radius: 50%;
        background-color: #CBC9C9;
        opacity: 12%;
      }
    }

    .item-scrollable-content {
      width: 100%;
      overflow-y: auto;
      padding-bottom: 20px;
      overflow-x: hidden;
      position: absolute;
      top: 174px;
      bottom: 10px;

      .inner-wrapper {
        padding: 0px 32px;
      }
    }
  }
}

//Page header
.page-header {

  @include secondary-font-regular;
  position: absolute;
  left: 48px;
  right: 49px;
  top: 56px;
  height: 32px;

  .box-logo {
    float: left;
  }

  .right-content {
    color: $black;
    position: absolute;
    right: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 26px;
  }

  .right-button {
    position: absolute;
    right: 0px;
    top: 0px;
    display: flex;

    .image-wrapper {
      margin-right: 48px;
      display: flex;
      align-items: center;
    }
  }
}

//Vertical line
.vertical-line {
  position: absolute;
  width: 25px;
  top: 0px;
  bottom: 0px;
  background-color: white;

  &.vl-1 {
    right: 25px;
  }

  &.vl-2 {
    right: 90px;
  }

  &.vr-1 {
    left: 25px;
  }

  &.vr-2 {
    left: 90px;
  }
}

//Loading view
.loading-view {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;


  .loading-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .lds-facebook {
      position: relative;
      width: 80px;
      height: 80px;
    }

    .lds-facebook div {
      display: inline-block;
      position: absolute;
      left: 8px;
      width: 16px;
      background: black;
      animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }

    .lds-facebook div:nth-child(1) {
      left: 8px;
      animation-delay: -0.24s;
    }

    .lds-facebook div:nth-child(2) {
      left: 32px;
      background: #00594f;
      animation-delay: -0.12s;
    }

    .lds-facebook div:nth-child(3) {
      left: 56px;
      animation-delay: unset;
    }

    @keyframes lds-facebook {
      0% {
        top: 8px;
        height: 64px;
      }

      50%, 100% {
        top: 24px;
        height: 32px;
      }
    }
  }
}

.message-row {
  max-width: 500px;

  .message-attachment {
    width: 85px;
    display: flex;
    flex-direction: column;

    .attachment-file {
      width: 50px;
      height: 65px;
      background-color: $white-six;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    p {
      text-align: center;
      font-size: 10px;
      font-weight: bold;
      margin-top: 10px;
    }
  }
}

.typing-loader {
  position: absolute;
  bottom: 33px;
  margin-left: 60px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  -webkit-animation: line 1s linear infinite alternate;
  -moz-animation: line 1s linear infinite alternate;
  animation: line 1s linear infinite alternate;
}

@-webkit-keyframes line {
  0% {

    background-color: rgba(0, 0, 0, 1);
    box-shadow: 12px 0 0 0 rgba(0, 0, 0, 0.2),
    24px 0 0 0 rgba(0, 0, 0, 0.2);

  }
  25% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0 0 0 rgba(0, 0, 0, 2),
    24px 0 0 0 rgba(0, 0, 0, 0.2);

  }
  75% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0 0 0 rgba(0, 0, 0, 0.2),
    24px 0 0 0 rgba(0, 0, 0, 2);

  }
}

@-moz-keyframes line {
  0% {

    background-color: rgba(0, 0, 0, 1);
    box-shadow: 12px 0 0 0 rgba(0, 0, 0, 0.2),
    24px 0 0 0 rgba(0, 0, 0, 0.2);

  }
  25% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0 0 0 rgba(0, 0, 0, 2),
    24px 0 0 0 rgba(0, 0, 0, 0.2);

  }
  75% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0 0 0 rgba(0, 0, 0, 0.2),
    24px 0 0 0 rgba(0, 0, 0, 2);

  }
}

@keyframes line {
  0% {

    background-color: rgba(0, 0, 0, 1);
    box-shadow: 12px 0 0 0 rgba(0, 0, 0, 0.2),
    24px 0 0 0 rgba(0, 0, 0, 0.2);

  }
  25% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0 0 0 rgba(0, 0, 0, 2),
    24px 0 0 0 rgba(0, 0, 0, 0.2);

  }
  75% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0 0 0 rgba(0, 0, 0, 0.2),
    24px 0 0 0 rgba(0, 0, 0, 2);

  }
}


