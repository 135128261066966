@import 'src/style/colors';
@import 'src/style/fonts';

.container {
  display: block;
  position: relative;
  padding-left: 24px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;

  p {
    @include secondary-font-regular;
    font-size: 16px;
    color: #07878F;
    width: 300px;
    margin-bottom: 0;

    &.bold {
      font-weight: bold;
    }
  }

  strong {
    font-size: 16px;
    color: #07878F;
    font-weight: normal;
    text-decoration: underline;
    cursor: pointer
  }
}

.container-radio {
  width: 100%;
  display: block;
  position: relative;
  padding-left: 15px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  p {
    font-size: 14px;
    color: #07878F;
    padding-left: 15px;
    width: 100%;

    &.bold {
      font-weight: bold;
    }
  }

  strong {
    font-size: 14px;
    color: #07878F;
    font-weight: normal;
    text-decoration: underline;
    cursor: pointer
  }
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.container-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  margin-top: 5px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #07878F;

  &.margin-2 {
    margin-top: 2px;
  }

  &.top {
    top: 18px;
  }
}

.checkmark-radio {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  margin-top: 5px;
  background-color: white;
  border: 1px solid #07878F;

  &.margin-2 {
    margin-top: 2px;
  }

  &.top {
    top: 18px;
  }
}

.container:hover input ~ .checkmark {
  background-color: white;
}

.container-radio:hover input ~ .checkmark-radio {
  background-color: white;
}

.container input:checked ~ .checkmark {
  background-color: white;
  border: 1px solid #07878F;
}

.container-radio input:checked ~ .checkmark-radio {
  background-color: white;
  border: 1px solid #07878F;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkmark-radio:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

.container-radio input:checked ~ .checkmark-radio:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 4px;
  top: 4px;
  width: 6px;
  height: 6px;
  background: #07878F;
}

.container-radio .checkmark-radio:after {
  left: 4px;
  top: 4.2px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #07878F;
}

.add-checklist {
  margin-top: 1rem;
  font-size: 14px;
  font-weight: bold;
  color: #07878F;
  text-decoration: underline;
  cursor: pointer;
}

