@import 'src/style/colors';
@import 'src/style/fonts';

.cdk-global-overlay-wrapper, .cdk-overlay-container {
  z-index: 99999 !important;
}

.nsm-overlay-open {
  z-index: 9999 !important;
}

.nsm-dialog.chat-modal {
  max-width: 100%;
  height: 100%;
  top: 0;

  .nsm-content {
    height: 100%;
    margin: 0 0 0 128px;
    padding: 0;
  }

  .nsm-dialog-btn-close {
    display: none;
  }
}

.nsm-dialog.conversation-modal {
  max-width: 100%;
  height: 100%;
  top: 0;

  .nsm-content {
    height: 100%;
    margin: 0;
    padding: 0;

    ::ng-deep .phone-number {
      border: 2px solid #05868E;
      width: 100% !important;
      height: 40px;
      margin-bottom: 20px;
      max-width: 400px;

      @media (max-width: 500px) {
        max-width: 300px;
      }

      &::placeholder {
        @include secondary-font-regular;
        font-size: 16px;
        color: #9d9ea0 !important;
      }
    }

    ::ng-deep .error-phone-number {
      border: 2px solid #ff0033;
      width: 100% !important;
      height: 40px;
      margin-bottom: 20px;
      max-width: 400px;

      @media (max-width: 500px) {
        max-width: 300px;
      }

      &::placeholder {
        @include secondary-font-regular;
        font-size: 16px;
        color: #9d9ea0 !important;
      }
    }

    ::ng-deep .iti {
      // width: 100% !important;
      // border: 1px solid #05868E;
      width: 100% !important;
      margin-bottom: 20px;
      max-width: 400px;

      @media (max-width: 500px) {
        max-width: 300px;
      }

      &::placeholder {
        @include secondary-font-regular;
        font-size: 16px;
        color: #9d9ea0 !important;
      }
    }


    .conversation-wrapper {
      width: 100%;
      height: 100vh;

      .header {
        margin-top: 174px;

        .logo-wrapper {
          position: absolute;
          img {
            width: 160px;
            height: 160px;
            margin-left: 240px;
            border-radius: 50%;
          }
        }

        .main-wrapper {
          width: 464px;
          margin: 0 auto;
          .new-conversation-wrapper {
            width: 464px;
            margin: 0 auto;

            .title {
              width: 400px;
              font-size: 44px;
              color: $black;
              @include secondary-font-bold;
              margin: 0 auto 1rem;
            }

            input {
              width: 400px;
              margin-left: 27px;
              border: none;
              border-bottom: 1px solid $white-light;
              padding-bottom: 15px;
            }

            span {
              margin-left: -20px;
              cursor: pointer
            }

            .contact-list {
              width: 464px;
              padding: 0 27px 0 27px;
              margin-top: 30px;

              .recent-list {
                font-size: 14px;
                color: $light-grey;
                margin-bottom: 2rem
              }

              .conversation-list-wrapper {
                margin-bottom: 1rem;
                border-bottom: 1px solid $white-light;

                img {
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                }

                .conversation-info {
                  width: 100%;

                  .conversation-info-title-wrapper {
                    margin-bottom: -14px;

                    .conversation-info-title {
                      font-size: 14px;
                      color: $black;
                    }

                    .conversation-info-subtitle {
                      font-size: 13px;
                      color: $light-grey;
                    }
                  }

                  .role-title {
                    font-size: 13px;
                    color: $light-grey;
                  }
                }
              }
            }

            .contact-list-wrapper {
              width: 464px;
              margin-top: 30px;
              height: 50vh;
              overflow-y: auto;
              overflow-x: hidden;

              .contact-list-title {
                font-size: 14px;
                padding-left: 32px;
                color: $light-grey;
              }

              .filtered-contact-wrapper {
                width: 464px;
                padding: 20px 32px 0 32px;
                border-top: 1px solid transparent;

                &:hover {
                  background-color: $white-six;
                  border-top: 1px solid $orange;
                }

                .filtered-contact {
                  border-bottom: 1px solid $white-light;
                }

                img {
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                }

                .contacts-info {
                  width: 100%;

                  .contacts-title-wrapper {
                    margin-bottom: -14px;

                    .contacts-title {
                      font-size: 14px;
                      color: $black;
                    }

                    .contacts-subtitle {
                      font-size: 13px;
                      color: $orange;
                      text-decoration: underline;
                      cursor: pointer;
                    }
                  }

                  .contacts-role {
                    font-size: 13px;
                    color: $light-grey;
                  }
                }
              }
            }
          }
        }
      }
    }

    .second-part-wrapper {
      position: fixed;
      top: 0;
      right: 0;
      width: 250px;
      height: 100vh;
      background: $warm-white;
      z-index: -1;
    }
  }

  .nsm-dialog-btn-close {
    display: none;
  }

  .dot-1, .dot-2 {
    position: fixed;
    height: 100%;
    width: 25px;
    bottom: 0;
    background-color: white;
    @media (max-width: 768px) {
      display: none;
    }
  }

  .dot-1 {
    right: 135px;
  }

  .dot-2 {
    right: 200px;
  }
}

.nsm-dialog.participant-modal {

  max-width: 100%;
  height: 100%;
  top: 0;

  .nsm-content {
    height: 100%;
    margin: 0;
    padding: 0;
  }

  .nsm-dialog-btn-close {
    display: none;
  }

  .dot-1, .dot-2 {
    position: fixed;
    height: 100%;
    width: 25px;
    bottom: 0;
    background-color: white;
    @media (max-width: 768px) {
      display: none;
    }
  }

  .dot-1 {
    right: 135px;
  }

  .dot-2 {
    right: 200px;
  }
}

.custom-modal-wrapper {
  .nsm-overlay-open {
    background-color: rgba(255, 255, 255, 0.8) !important;
  }
}

.nsm-dialog.project-details-modal {
  width: 324px;
  margin: 6% auto;

  .nsm-content {
    width: 324px;
    padding: 0;

    .nsm-dialog-btn-close {
      display: none;
    }
  }
}

.nsm-dialog.event-modal {
  width: 324px;
  margin: 11% auto;

  .nsm-content {
    width: 324px;
    padding: 0;

    .nsm-dialog-btn-close {
      display: none;
    }
  }
}

.event-modal-upload-wrapper {
  .nsm-overlay-open {
    position: absolute;
    right: 0;
    left: -128px;
  }
}

.nsm-dialog.event-modal-upload {
  width: 324px;
  margin: 11% auto;

  .nsm-content {
    width: 324px;
    margin-left: 0;
    padding: 0;

    .nsm-dialog-btn-close {
      display: none;
    }
  }
}

.nsm-dialog.confirm-modal {
  width: 450px;
  height: 200px;
  margin: 11% auto;

  .nsm-content {
    width: 450px;
    height: 200px;
    padding: 0;

    .nsm-dialog-btn-close {
      display: none;
    }
  }
}


.nsm-dialog.basic-modal {
  width: 500px;
  min-height: 570px;
  max-height: 570px;
  top: 80px;

  @media (max-width: 500px) {
    width: 450px;
    top: 50px;
  }

  @media (max-width: 470px) {
    width: 350px;
    top: 50px;
  }

  @media (max-width: 320px) {
    width: 310px;
    top: 50px;
  }

  .nsm-content {
    min-height: 570px;
    margin: 0;
    padding: 30px;

    .nsm-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .company-verification {

        .confirmation-title {
          color: #000000;
          @include secondary-font-regular;
          font-size: 18px;
          letter-spacing: 0.47px;
          line-height: 28px;
          text-align: center;
        }

        p.title {
          margin: 0px;
        }

        .button-wrapper-sign {
          width: 100%;
        }

        .sign-button {
          @include secondary-font-medium;
          margin: 0 auto;
          width: 200px;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #05868E;
          color: #ffffff;
          font-size: 18px;
          text-transform: uppercase;
          border: none;
          outline: none;
          box-shadow: none;
          cursor: pointer;

          &:disabled {
            cursor: not-allowed;
          }

          img {
            margin-right: 8px;
            width: 33px;
          }
        }

        .sign-options {
          color: #05868E;
          @include secondary-font-medium;
          font-size: 16px;
          letter-spacing: 0;
          line-height: 14px;
          text-align: center;
          text-transform: uppercase;
          cursor: pointer;
          margin-top: 15px;
        }

      }

      .credit-check {

        .confirmation-title {
          color: #000000;
          @include secondary-font-regular;
          font-size: 18px;
          letter-spacing: 0.47px;
          line-height: 28px;
          text-align: center;
        }

        .credit-not-valid {
          color: #ff0033;
          font-weight: bold;
          margin: 0px 60px;;
        }

      }

      .login-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .close-modal {
          width: 35px;
          position: absolute;
          top: 0;
          right: 0;
          cursor: pointer;
        }

        .login-form {
          .qr-code-wrapper {
            width: 300px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          img {
            width: 150px;
            height: auto;
          }

          .sign-options-wrapper {
            .show-password-icon {
              height: 40px;
              cursor: pointer;
            }
            display: flex !important;
            flex-direction: column !important;
            align-items: center !important;
            align-content: space-between !important;

            .input-field {
              width: 400px;

              @media (max-width: 500px) {
                width: 300px;
              }
            }

            .sign-button {
              @include secondary-font-medium;
              margin: 0 auto;
              margin-bottom: 20px;
              width: 200px;
              height: 60px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #05868E;
              color: #ffffff;
              font-size: 18px;
              text-transform: uppercase;
              border: none;
              outline: none;
              box-shadow: none;
              cursor: pointer;

              img {
                width: 33px;
                margin: 0px;
              }

              &:disabled {
                cursor: not-allowed;
              }

              .sign-image {
                width: 33px;
              }

              @media (max-width: 500px) {
                width: 100%;
              }
            }

            .options-title {
              color: #02868E;
              @include secondary-font-bold;
              font-size: 24px;
              letter-spacing: 0;
              line-height: 37px;
              text-align: center;
              margin: 0 auto;
            }

            div .selected-dial-code {
              color: #02868E;
            }

            ul .iti__country-list {
              color: #02868E;
            }

            .options-input {
              border: 1px solid #05868E;
              background-color: #ffffff;
              @include secondary-font-regular;
              font-size: 16px;
              width: 100%;
              margin-top: 20px;
              border-radius: 0;

              &::placeholder {
                @include secondary-font-regular;
                font-size: 16px;
                color: #05868E;
              }
            }

            .phone-number {
              border: 2px solid #05868E;
              width: 100% !important;
              height: 40px;
              margin-bottom: 20px;
              &::placeholder {
                @include secondary-font-regular;
                font-size: 16px;
                color: #9d9ea0 !important;
              }
            }

            .error-phone-number {
              border: 2px solid #ff0033;
              width: 100% !important;
              height: 40px;
              margin-bottom: 20px;
              &::placeholder {
                @include secondary-font-regular;
                font-size: 16px;
                color: #9d9ea0 !important;
              }
            }

            .iti {
              // width: 100% !important;
              // border: 1px solid #05868E;
              width: 100% !important;
              margin-bottom: 20px;
              &::placeholder {
                @include secondary-font-regular;
                font-size: 16px;
                color: #9d9ea0 !important;
              }        // height: 40px;
            }

            .code-title {
              color: #000000;
              @include secondary-font-regular;
              font-size: 18px;
              letter-spacing: 0.47px;
              line-height: 28px;
              text-align: center;
            }

            .bank-id-title {
              color: #000000;
              @include secondary-font-regular;
              font-size: 16px;
              letter-spacing: 0.42px;
              line-height: 20px;
              text-align: center;
              margin-top: 24px;
            }

            .bank-id-explanation {
              color: #4D4D4D;
              @include secondary-font-regular;
              font-size: 16px;
              letter-spacing: 0.5px;
              line-height: 24px;
              text-align: center;
            }

            .confirmation-title {
              margin-bottom: 0px;
              text-align: left;
              font-size: 14px;
            }

            .confirmation-error {
              margin-top: -20px;
              margin-bottom: 20px;
              color: #ff0033 !important;
              text-align: left;
              font-size: 12px;
              font-weight: bold;
            }

            .confirmation-input {
              border: 2px solid #05868E;
              background-color: #ffffff;
              color: #05868E !important;
              @include secondary-font-regular;
              font-size: 16px;
              width: 100%;
              margin: 0;
              margin-bottom: 20px;
              border-radius: 0;
              height: 40px;
              max-width: 400px;

              @media (max-width: 500px) {
                max-width: 300px;
              }

              &::placeholder {
                @include secondary-font-regular;
                font-size: 16px;
                color: #9d9ea0 !important;
              }
            }

            .error-confirmation-input {
              border: 2px solid #ff0033;
              margin-bottom: 20px;
              color: #05868E !important;
              height: 40px;
              max-width: 400px;

              @media (max-width: 500px) {
                max-width: 300px;
              }

              &::placeholder {
                @include secondary-font-regular;
                font-size: 16px;
                color: #9d9ea0 !important;
              }
            }
          }
        }

        .loading-view {
          margin: 20px auto;
          width: 200px;
          height: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #225870;
          border-radius: 50%;
        }

        .spinner {
          margin: 100px auto;
          width: 50px;
          height: 40px;
          text-align: center;
          font-size: 10px;
        }

        .spinner > div {
          background-color: #fff;
          height: 100%;
          width: 6px;
          display: inline-block;

          -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
          animation: sk-stretchdelay 1.2s infinite ease-in-out;
        }

        .spinner .rect2 {
          -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
        }

        .spinner .rect3 {
          -webkit-animation-delay: -1.0s;
          animation-delay: -1.0s;
        }

        .spinner .rect4 {
          -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
        }

        .spinner .rect5 {
          -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
        }

        @-webkit-keyframes sk-stretchdelay {
          0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
          20% { -webkit-transform: scaleY(1.0) }
        }

        @keyframes sk-stretchdelay {
          0%, 40%, 100% {
            transform: scaleY(0.4);
            -webkit-transform: scaleY(0.4);
          }  20% {
              transform: scaleY(1.0);
              -webkit-transform: scaleY(1.0);
            }
        }

        .main-page {
          width: 80%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        form {
          max-width: 400px;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .project-title {
            font-size: 54px;
            font-family: barlow_extra_bold, sans-serif;
            color: #00594f;
            text-align: center;
          }

          p {
            &.error {
              color: red;
            }
          }

          h2 {
            font-weight: bold;
            text-align: center;
            font-size: 24px;
            text-transform: uppercase;
            margin-bottom: 2rem;
          }

          body {
            color: #05868E;
          }

          input {
            font-size: 16px;
            font-family: "sofia_pro_regular", sans-serif;
            padding: 10px;
            border: none;
            margin-bottom: 10px;
            color: #05868E;
            border: 1px solid #05868E;
            width: 400px;
            outline: none;
            background-color: #ffffff;
            @include secondary-font-regular;
            border-radius: 0;

            &::placeholder {
              @include secondary-font-regular;
              font-size: 16px;
              color: #05868E;
            }
          }

          label {
            text-align: right;
            color: red;
            font-size: 12px;
          }


          ::-webkit-input-placeholder { /* Edge */
            color: #00594f;
          }

          :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #00594f;
          }

          ::placeholder {
            color: #00594f;
          }

          .buttons-form {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 1rem;

            button {
              cursor: pointer;
              font-weight: bold;
              font-size: 14px;

              &:disabled {
                cursor: not-allowed;
              }
            }

            .register-reset-button {
              @include secondary-font-regular;
              font-size: 14px;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              align-items: flex-end;

              a {
                color: #00594f;
                cursor: pointer;
              }
            }
          }
        }
      }

    }
  }

  .close-modal-wrapper {
    width: 90%;
    margin: 0 auto;

    img {
      width: 184px;
      margin: 0 auto;
    }

    .title-cancel {
      color: #C63527;
      @include secondary-font-bold;
      font-size: 24px;
      letter-spacing: 0;
      line-height: 37px;
      margin-bottom: 22px;
      text-align: center;
      text-transform: capitalize;
    }

    .subtitle-cancel {
      color: #4D4D4D;
      @include secondary-font-regular;
      font-size: 16px;
      letter-spacing: 0.27px;
      line-height: 24px;
      text-align: center;
      margin-bottom: 11px;
    }

    .text-cancel {
      color: #000000;
      @include secondary-font-regular;
      font-size: 16px;
      letter-spacing: 0.42px;
      line-height: 20px;
      text-align: center;
      margin-bottom: 0;

      &.warning {
        color: #C63527;
      }
    }

    .continue-button {
      width: 80%;
      height: 56px;
      margin: 36px auto 0 auto;
      background-color: #C63527;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFFFFF;
      @include secondary-font-regular;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 14px;
      text-align: center;
      text-transform: uppercase;
      border: none;
      outline: none;
      box-shadow: none;
      cursor: pointer;
      @media (max-width: 830px) {
        font-size: 14px;
      }
    }

    .cancel-button {
      width: 80%;
      height: 56px;
      margin: 0 auto;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #C63527;
      @include secondary-font-regular;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 14px;
      text-align: center;
      text-transform: uppercase;
      border: none;
      outline: none;
      box-shadow: none;
      cursor: pointer;
    }
  }

  .close-modal {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    cursor: pointer;
  }

  .close-modal-button {

     height: 72px;
     width: 158px;
     background-color: #05868E;
     color: #FFFFFF;
     @include secondary-font-regular;
     font-size: 16px;
     letter-spacing: 0;
     line-height: 14px;
     text-align: center;
     margin: 40px 0;
     border: none;
     outline: none;
     text-transform: uppercase;
     cursor: pointer;
     padding: 0;
     &:disabled {
       cursor: not-allowed;
     }
     &.special {
       margin: 20px 0 0 0;
       @media (max-width: 950px) {
         width: 100%;
       }
     }
     &.form {
       margin: 0;
       @media (max-width: 766px) {
         width: 100%;
       }
     }
   }

  p {
    &.title {
      @include secondary-font-bold;
      width: 90%;
      color: #02868E;
      @include secondary-font-bold;
      font-size: 24px;
      text-align: center;
      letter-spacing: 0;
      line-height: 37px;
      margin: 40px auto 0 auto;
    }
    &.text {
      @include secondary-font-regular;
      margin: 40px auto 0 auto;
      width: 90%;
      font-size: 14px;
      max-height: 415px;
      overflow-y: auto;
      padding-right: 10px;
    }
    &.final-title {
      color: #00868E;
      @include secondary-font-bold;
      font-size: 30px;
      letter-spacing: 0.5px;
      text-align: center;
    }

    &.final-text {
      color: #000000;
      @include secondary-font-regular;
      font-size: 18px;
      letter-spacing: 0.47px;
      text-align: center;
      max-width: 400px;
    }
  }

  .success-image {
    margin: 20px auto;
    display: flex;
  }

  .other-info {
    margin: 10px auto 10px auto;
    text-align: center;
    color: #000000;
    @include secondary-font-regular;
    font-size: 16px;
    letter-spacing: 0.42px;
    line-height: 20px;
  }

  .error-wrapper-button {
    margin-top: 10px;
  }

  .nsm-dialog-btn-close {
    display: none;
  }

  .dot-1, .dot-2 {
    position: fixed;
    height: 100%;
    width: 25px;
    bottom: 0;
    background-color: white;
    @media (max-width: 768px) {
      display: none;
    }
  }

  .dot-1 {
    right: 135px;
  }

  .dot-2 {
    right: 200px;
  }
}
