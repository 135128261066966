.m-0 {
  margin: 0;
}
.m-1 {
  margin: 1px;
}
.m-2 {
  margin: 2px;
}
.m-3 {
  margin: 3px;
}
.m-4 {
  margin: 4px;
}
.m-5 {
  margin: 5px;
}
.m-10 {
  margin: 10px;
}
.m-20 {
  margin: 20px;
}
.m-30 {
  margin: 30px;
}
.m-40 {
  margin: 40px;
}

.p-0 {
  padding: 0;
}
.p-1 {
  padding: 1px;
}
.p-2 {
  padding: 2px;
}
.p-3 {
  padding: 3px;
}
.p-4 {
  padding: 4px;
}
.p-5 {
  padding: 5px;
}
.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 20px;
}

.p-30 {
  padding: 30px;
}

.p-40 {
  padding: 40px;
}

.margin-top-10{
  margin-top:10px;
}
.margin-top-20{
  margin-top: 20px;
}
.margin-top-40{
  margin-top: 40px;
}
.margin-bottom-10{
  margin-bottom: 10px;
}

.mt0 {
  margin-top: 0 !important;
}
.mb-20 {
  margin-bottom: 20px;
}
.mt5 {
  margin-top: 5px;
}
.mt10 {
  margin-top: 10px !important;
}
.mt15 {
  margin-top: 15px;
}
.mt20 {
  margin-top: 20px;
}
.mr13-5 {
  margin-right: 13.5px;
}
.mt30 {
  margin-top: 30px;
}
.mt40 {
  margin-top: 40px;
}
.mb5 {
  margin-bottom: 5px;
}
.mb6 {
  margin-bottom: 6px;
}
.mb8 {
  margin-bottom: 8px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb15 {
  margin-bottom: 15px;
}
.mb20 {
  margin-bottom: 20px;
}
.ml5 {
  margin-left: 5px;
}
.ml10 {
  margin-left: 10px;
}
.ml15 {
  margin-left: 15px;
}
.ml20 {
  margin-left: 20px;
}
.ml24 {
  margin-left: 24px !important;
}
.mr5 {
  margin-right: 5px;
}
.mr10 {
  margin-right: 10px;
}
.mr15 {
  margin-right: 15px;
}
.mr20 {
  margin-right: 20px;
}
.mr30 {
  margin-right: 30px;
}
.mr40 {
  margin-right: 40px;
}

.pt5 {
  padding-top: 5px;
}
.pt10 {
  padding-top: 10px;
}
.pt15 {
  padding-top: 15px;
}
.pt20 {
  padding-top: 20px;
}
.pt30 {
  padding-top: 30px;
}
.pt40 {
  padding-top: 40px;
}
.pb5 {
  padding-bottom: 5px;
}
.pb10 {
  padding-bottom: 10px;
}
.pb15 {
  padding-bottom: 15px;
}
.pb30 {
  padding-bottom: 30px;
}
.pb40 {
  padding-bottom: 40px;
}
.pb20 {
  padding-bottom: 20px;
}
.pl5 {
  padding-left: 5px;
}
.pl10 {
  padding-left: 10px;
}
.pl15 {
  padding-left: 15px;
}
.pl20 {
  padding-left: 20px;
}
.pl30 {
  padding-left: 30px;
}
.pl40 {
  padding-left: 40px;
}
.pr5 {
  padding-right: 5px;
}
.pr10 {
  padding-right: 10px;
}
.pr15 {
  padding-right: 15px;
}
.pr20 {
  padding-right: 20px;
}
.pr30 {
  padding-right: 30px;
}
.pr40 {
  padding-right: 40px;
}
.mtr1 {
  margin-top: 1rem;
}
.mtr2 {
  margin-top: 2rem;
}
.mtr3 {
  margin-top: 3rem;
}
.mtr4 {
  margin-top: 4rem;
}
.mtr5 {
  margin-top: 5rem;
}
.mbr1 {
  margin-bottom: 1rem;
}
.mbr2 {
  margin-bottom: 2rem;
}
.mbr3 {
  margin-bottom: 3rem;
}
.mbr4 {
  margin-bottom: 4rem;
}
.mbr5 {
  margin-bottom: 5rem;
}
.mrr1 {
  margin-right: 1rem;
}
.mrr2 {
  margin-right: 2rem;
}
.mrr3 {
  margin-right: 3rem;
}
.mrr4 {
  margin-right: 4rem;
}
.mrr5 {
  margin-right: 5rem;
}
.mlr1 {
  margin-left: 1rem;
}
.mlr2 {
  margin-left: 2rem;
}
.mlr3 {
  margin-left: 3rem;
}
.mlr4 {
  margin-left: 4rem;
}
.mlr5 {
  margin-left: 5rem;
}
.minus-margin-top-15 {
  margin-top: -15px !important;
}
.minus-margin-top-20 {
  margin-top: -20px !important;
}
.minus-margin-top-35 {
  margin-top: -35px;
}
