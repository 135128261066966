$white-default: #FFFFFF;
$white: #E3E2E2;
$white-light: #180606;
$white-second: #f1f0ec;
$white-third: #F9F9F9;
$white-four: #F9F8F7;
$white-five: #FCFCFC;
$white-six: #F3F2EE;
$white-seven: #f0efeb;
$drag: #f5f5f5;
$black: #212121;
$orange: #E06C51;
$grey-main: #e1e1e1;
$warm-white: #F8F7F6;
$lightest-grey: #bbbbbb;
$warm-grey: #73716F;
$light-grey: #71706F;
$light-blue: #5ab0f8;
$dark-red: #ce3030;
