@import 'src/style/colors';
@import 'src/style/fonts';

.mat-menu-panel {
  width: 232px !important;
  padding: 16px 0 !important;
  margin-left: -76px !important;
  margin-top: 8px !important;
  background-color: #FFFFFF !important;
  border-radius: 0 !important;
  border: none !important;
  border-top: 1px solid #E06C51 !important;
  box-shadow: 0 8px 24px 0 rgba(220,219,212,0.56) !important;

  &.mat-menu-above {
    border-top: none !important;
    border-bottom: 1px solid #E06C51 !important;
  }

  .mat-menu-content:not(:empty) {
    padding: 0 !important;
  }

  &.task {
    width: 150px !important;
    margin-left: -132px !important;
  }

  &.conversation {
    margin-left: -204px !important;
  }
}

.more-menu-upload {
  width: 100%;
  height: 40px;
  background: transparent;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  input {
    width: 232px;
    position: absolute;
    padding-left: 24px;
    padding-right: 24px;
    height: 20px;
    opacity: 0;
    z-index: 9;
    cursor: pointer;
  }

  p {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
    color: $warm-grey;
    text-decoration: none;
    cursor: pointer;
    padding: 0 24px;
    margin-bottom: 0;
  }

  &:hover {
    background: $white-six;
    p {
      color: $black;
    }
  }
}


.divider {
  margin: 6px 24px;
  height: 1px;
  background-color: #e0dfdf;
}

.more-menu {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;

  &.no-border-bottom {
    border-bottom: 0;
  }

  &.custom-padding {
    padding: 6px 0 6px 19px;
  }

  p, a {
    &.more-menu-text, &.more-menu-text {
      width: 100%;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
      color: $warm-grey;
      text-decoration: none;
      cursor: pointer;
      padding: 0 24px;
      margin-bottom: 0;

      &.border-top {
        border-top: 1px solid $white-six;
      }
    }
  }

  i {
    margin-left: 10px;
  }

  &:hover {
    background: $white-six;

    p, a {
      &.more-menu-text, &.more-menu-text {
        color: $black;

        &.border-top {
          border-top: 1px solid transparent;
        }
      }
    }
  }
}

.more-menu:last-child {
  p, a {
    &.more-menu-text {
      border-bottom: none;
    }
  }
}

.dropdown-navbar {
  &.account {
    margin-left: -11.5rem !important;
  }

  &.chat {
    margin-left: -13rem !important;
  }

  &.task, &.documents {
    margin-left: 0 !important;
  }

  padding: 16px 0 !important;
  border-radius: 0 !important;
  margin-left: -12.5rem !important;
  margin-top: 8px !important;
  width: 232px !important;
  background-color: #FFFFFF !important;
  border: none !important;
  border-top: 1px solid #E06C51 !important;
  box-shadow: 0 8px 24px 0 rgba(220,219,212,0.56) !important;
}

.p-dropdown-span {
  vertical-align: middle;
  @include secondary-font-regular;
  font-weight: 600;
  color: $black;
  font-size: 13px;
}

.p-dropdown-header {
  width: 176px;
  height: 40px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}

.p-dropdown-label {
  font-size: 13px;
  margin-top: 1px;
  height: 20px !important;
  @include secondary-font-regular;
  font-weight: 600;
  color: $black;
  width: 176px;
  padding: 0 24px !important;
}

.dropdown-menu {
  .locations {
    border-radius: 0px;
    padding: 0px;
  }
}